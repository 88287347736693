import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Posts from '../view/posts';
import '../style/style.scss';

const IndexPage = props => (
  <Layout
    {...props}
    description={`Fragment of my thoughts escaping into the 🌐`}
    title={`Posts`}
  >
    <Posts />
  </Layout>
);

export default IndexPage;

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
        description
        url
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1000
      filter: {
        frontmatter: { linkonly: { ne: true } }
        fields: { slug: { regex: "//post/.*/" } }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
          }
        }
      }
    }
  }
`;
